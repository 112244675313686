export const Multicall = [
  {
    inputs: [
      { internalType: 'address', name: '_storage', type: 'address' },
      { internalType: 'address', name: '_pairInfos', type: 'address' },
      { internalType: 'address', name: '_pairsStorage', type: 'address' },
      { internalType: 'address', name: '_trading', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'aggregate',
    outputs: [
      { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
      { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bool', name: 'allowFailure', type: 'bool' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call3[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'aggregate3',
    outputs: [
      {
        components: [
          { internalType: 'bool', name: 'success', type: 'bool' },
          { internalType: 'bytes', name: 'returnData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Result[]',
        name: 'returnData',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bool', name: 'allowFailure', type: 'bool' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call3Value[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'aggregate3Value',
    outputs: [
      {
        components: [
          { internalType: 'bool', name: 'success', type: 'bool' },
          { internalType: 'bytes', name: 'returnData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Result[]',
        name: 'returnData',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'blockAndAggregate',
    outputs: [
      { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
      { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
      {
        components: [
          { internalType: 'bool', name: 'success', type: 'bool' },
          { internalType: 'bytes', name: 'returnData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Result[]',
        name: 'returnData',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBasefee',
    outputs: [{ internalType: 'uint256', name: 'basefee', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
    name: 'getBlockHash',
    outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBlockNumber',
    outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getChainId',
    outputs: [{ internalType: 'uint256', name: 'chainid', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentBlockCoinbase',
    outputs: [{ internalType: 'address', name: 'coinbase', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentBlockDifficulty',
    outputs: [{ internalType: 'uint256', name: 'difficulty', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentBlockGasLimit',
    outputs: [{ internalType: 'uint256', name: 'gaslimit', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentBlockTimestamp',
    outputs: [{ internalType: 'uint256', name: 'timestamp', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
    name: 'getEthBalance',
    outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'getFirstEmptyTradeIndexes',
    outputs: [{ internalType: 'uint256[]', name: 'firstEmptyTradeIndexes', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getLastBlockHash',
    outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'trader', type: 'address' },
      { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getLiquidationPrice',
    outputs: [{ internalType: 'uint256', name: 'liquidationPrice', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getLongShortRatios',
    outputs: [
      { internalType: 'uint256[]', name: 'longRatio', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'shortRatio', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMargins',
    outputs: [
      { internalType: 'uint256[]', name: 'rolloverFeePerBlockP', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'rolloverFeePerBlockLong', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'rolloverFeePerBlockShort', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'getOpenLimitOrdersCounts',
    outputs: [{ internalType: 'uint256[]', name: 'openLimitOrdersCounts', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_trader', type: 'address' }],
    name: 'getPendingOrder',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'orderId', type: 'uint256' },
          {
            components: [
              {
                components: [
                  { internalType: 'address', name: 'trader', type: 'address' },
                  { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
                  { internalType: 'uint256', name: 'index', type: 'uint256' },
                  { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
                  { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
                  { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
                  { internalType: 'bool', name: 'buy', type: 'bool' },
                  { internalType: 'uint256', name: 'leverage', type: 'uint256' },
                  { internalType: 'uint256', name: 'tp', type: 'uint256' },
                  { internalType: 'uint256', name: 'sl', type: 'uint256' },
                  { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
                ],
                internalType: 'struct ITradingStorage.Trade',
                name: 'trade',
                type: 'tuple',
              },
              { internalType: 'uint256', name: 'block', type: 'uint256' },
              { internalType: 'uint256', name: 'wantedPrice', type: 'uint256' },
              { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.PendingMarketOrder',
            name: 'orderInfo',
            type: 'tuple',
          },
        ],
        internalType: 'struct IMulticall.AggregatedPendingOrder[]',
        name: 'pendingOrders',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'userAddress', type: 'address' }],
    name: 'getPositions',
    outputs: [
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.Trade',
            name: 'trade',
            type: 'tuple',
          },
          {
            components: [
              { internalType: 'uint256', name: 'openInterestUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'tpLastUpdated', type: 'uint256' },
              { internalType: 'uint256', name: 'slLastUpdated', type: 'uint256' },
              { internalType: 'bool', name: 'beingMarketClosed', type: 'bool' },
              { internalType: 'uint256', name: 'lossProtection', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.TradeInfo',
            name: 'tradeInfo',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'rolloverFee', type: 'uint256' },
          { internalType: 'uint256', name: 'liquidationPrice', type: 'uint256' },
        ],
        internalType: 'struct IMulticall.AggregatedTrade[]',
        name: '',
        type: 'tuple[]',
      },
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'price', type: 'uint256' },
              { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
              { internalType: 'uint256', name: 'block', type: 'uint256' },
              { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.OpenLimitOrder',
            name: 'order',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'liquidationPrice', type: 'uint256' },
        ],
        internalType: 'struct IMulticall.AggregatedOrder[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'userAddress', type: 'address' },
      { internalType: 'uint256', name: 'startPairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'endPairIndex', type: 'uint256' },
      { internalType: 'uint256', name: 'maxTradesPerPair', type: 'uint256' },
    ],
    name: 'getPositionsForPairIndexes',
    outputs: [
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'initialPosToken', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSizeUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'openPrice', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.Trade',
            name: 'trade',
            type: 'tuple',
          },
          {
            components: [
              { internalType: 'uint256', name: 'openInterestUSDC', type: 'uint256' },
              { internalType: 'uint256', name: 'tpLastUpdated', type: 'uint256' },
              { internalType: 'uint256', name: 'slLastUpdated', type: 'uint256' },
              { internalType: 'bool', name: 'beingMarketClosed', type: 'bool' },
              { internalType: 'uint256', name: 'lossProtection', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.TradeInfo',
            name: 'tradeInfo',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'rolloverFee', type: 'uint256' },
          { internalType: 'uint256', name: 'liquidationPrice', type: 'uint256' },
        ],
        internalType: 'struct IMulticall.AggregatedTrade[]',
        name: '',
        type: 'tuple[]',
      },
      {
        components: [
          {
            components: [
              { internalType: 'address', name: 'trader', type: 'address' },
              { internalType: 'uint256', name: 'pairIndex', type: 'uint256' },
              { internalType: 'uint256', name: 'index', type: 'uint256' },
              { internalType: 'uint256', name: 'positionSize', type: 'uint256' },
              { internalType: 'bool', name: 'buy', type: 'bool' },
              { internalType: 'uint256', name: 'leverage', type: 'uint256' },
              { internalType: 'uint256', name: 'tp', type: 'uint256' },
              { internalType: 'uint256', name: 'sl', type: 'uint256' },
              { internalType: 'uint256', name: 'price', type: 'uint256' },
              { internalType: 'uint256', name: 'slippageP', type: 'uint256' },
              { internalType: 'uint256', name: 'block', type: 'uint256' },
              { internalType: 'uint256', name: 'executionFee', type: 'uint256' },
            ],
            internalType: 'struct ITradingStorage.OpenLimitOrder',
            name: 'order',
            type: 'tuple',
          },
          { internalType: 'uint256', name: 'liquidationPrice', type: 'uint256' },
        ],
        internalType: 'struct IMulticall.AggregatedOrder[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'tryAggregate',
    outputs: [
      {
        components: [
          { internalType: 'bool', name: 'success', type: 'bool' },
          { internalType: 'bytes', name: 'returnData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Result[]',
        name: 'returnData',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
      {
        components: [
          { internalType: 'address', name: 'target', type: 'address' },
          { internalType: 'bytes', name: 'callData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Call[]',
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'tryBlockAndAggregate',
    outputs: [
      { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
      { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
      {
        components: [
          { internalType: 'bool', name: 'success', type: 'bool' },
          { internalType: 'bytes', name: 'returnData', type: 'bytes' },
        ],
        internalType: 'struct Multicall3.Result[]',
        name: 'returnData',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_storage', type: 'address' },
      { internalType: 'address', name: '_pairInfos', type: 'address' },
      { internalType: 'address', name: '_pairsStorage', type: 'address' },
      { internalType: 'address', name: '_trading', type: 'address' },
    ],
    name: 'updateContracts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;
